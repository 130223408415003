import axios from "axios";
import { APP_URL, BACKEND_URL } from "./config";

export const setJwtTokenAndRedirect = (jwt_token: string) => {
  localStorage.clear();
  localStorage.setItem("auth_token", jwt_token);
  passTokenToApp(jwt_token);
};

export const logInWithAccessToken = async (
  accessToken: string,
  profile_info: any,
) => {
  const response = await axios.post(
    BACKEND_URL + "/api/auth/google/login/",
    {
      access_token: accessToken,
      profile: profile_info,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  const jwt_token = response.data;
  setJwtTokenAndRedirect(jwt_token);
  return jwt_token;
};

export const passTokenToApp = (jwt_token: string) => {
  if (window.parent) {
    // inside webview
    window.parent.postMessage({
      event: "log-in",
      status: "success",
      token: jwt_token,
    });
  } else {
    // on web app
    window.location.href = `${APP_URL}?t=${jwt_token}`;
  }
};

export function handleClickBack(e: any) {
  if (window.parent) {
    // inside webview
    window.parent.postMessage({
      event: "back",
    });
    e.stopDefault();
    e.stopPropagation();
  }
}
