export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "https://services.jumpballapp.com";
export const APP_URL =
  process.env.REACT_APP_APP_URL || "https://app.jumpballapp.com";
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  "812889896112-6dp9o1c7uqdjlgf07ud8esg0ov95j2ct.apps.googleusercontent.com";
export const STRIPE_PRODUCT_BASIC_YEARLY_KEY =
  process.env.REACT_APP_STRIPE_PRODUCT_BASIC_YEARLY_KEY || "basic_yearly";
export const STRIPE_PRODUCT_BASIC_MONTHLY_KEY =
  process.env.REACT_APP_STRIPE_PRODUCT_BASIC_MONTHLY_KEY || "basic_monthly";
export const PUBLIC_POSTHOG_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
export const PUBLIC_POSTHOG_HOST =
  process.env.REACT_APP_PUBLIC_POSTHOG_HOST || "https://eu.i.posthog.com";
