import { Button } from "@/components/ui/button";
import { Users, MapPin, Chrome, Play, Calendar } from "lucide-react";
import { Link } from "react-router-dom";
import logoWhite from "@/assets/img/logo.png";

export default function LandingPage() {
  const webUrl = "https://app.jumpballapp.com/auth/login";

  const scrollIntoSection = (name: string, e: any) => {
    let section = document.getElementById(name);
    e.preventDefault();
    section && section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-14 flex items-center bg-transparent fixed text-primary-foreground w-full text-shadow">
        <Link className="flex items-center justify-center" to="/">
          <img
            src={logoWhite}
            alt="Logo"
            style={{
              height: 30,
              WebkitFilter: "drop-shadow(1px 2px 2px #00000063)",
              filter: "drop-shadow(1px 2px 2px #00000063)",
            }}
          />
        </Link>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#features"
            onClick={(e) => scrollIntoSection("features", e)}
          >
            Features
          </a>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#how-it-works"
            onClick={(e) => scrollIntoSection("how-it-works", e)}
          >
            How It Works
          </a>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#download"
            onClick={(e) => scrollIntoSection("download", e)}
          >
            Download
          </a>
        </nav>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 hero-bg lg:h-screen">
          <div className="container px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
              <div className="flex flex-col justify-center space-y-4 text-center lg:text-left">
                <div className="space-y-2">
                  <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-primary-foreground text-shadow">
                    Connect, Play, and Score with JumpBall App
                  </h1>
                  <p className="max-w-[600px] text-background md:text-xl text-shadow">
                    Organize basketball games and meet new players in your area.
                    Your next great game is just a tap away!
                  </p>
                </div>
                <div className="flex flex-col gap-2 min-[400px]:flex-row justify-center lg:justify-start">
                  <Button
                    className="text-primary-foreground hover:bg-primary-foreground hover:text-primary"
                    onClick={() => document.location.replace(webUrl)}
                  >
                    <Chrome className="mr-2 h-5 w-5" />
                    Try Online {">"}
                  </Button>
                  <a
                    href="#features"
                    onClick={(e) => scrollIntoSection("features", e)}
                  >
                    <Button
                      variant="outline"
                      className="text-primary-foreground border-primary-foreground hover:text-primary"
                    >
                      Learn More
                    </Button>
                  </a>
                </div>
              </div>
              <div className="mx-auto flex items-center justify-center">
                <img
                  src="https://shakeasipper.com/static/media/phone2.203c50be3f27ccc93989.png"
                  alt="JumpBall App Preview"
                  className="rounded-2xl object-cover"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12">
              Key Features
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col items-center text-center">
                <Users className="h-12 w-12 mb-4 text-orange-500" />
                <h3 className="text-xl font-bold mb-2">Find Players</h3>
                <p className="text-gray-500">
                  Connect with basketball enthusiasts in your area.
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <MapPin className="h-12 w-12 mb-4 text-orange-500" />
                <h3 className="text-xl font-bold mb-2">Locate Courts</h3>
                <p className="text-gray-500">
                  Discover nearby basketball courts for your games.
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <Calendar className="h-12 w-12 mb-4 text-orange-500" />
                <h3 className="text-xl font-bold mb-2">Schedule Matches</h3>
                <p className="text-gray-500">
                  Easily organize and join basketball games.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id="how-it-works"
          className="w-full py-12 md:py-24 lg:py-32 bg-gray-100"
        >
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12">
              How It Works
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col items-center text-center">
                <div className="w-12 h-12 rounded-full bg-orange-500 text-white flex items-center justify-center text-2xl font-bold mb-4">
                  1
                </div>
                <h3 className="text-xl font-bold mb-2">Create Your Profile</h3>
                <p className="text-gray-500">
                  Sign up and set your preferences for playing basketball.
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="w-12 h-12 rounded-full bg-orange-500 text-white flex items-center justify-center text-2xl font-bold mb-4">
                  2
                </div>
                <h3 className="text-xl font-bold mb-2">
                  Find or Create a Game
                </h3>
                <p className="text-gray-500">
                  Browse available games or start your own match.
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="w-12 h-12 rounded-full bg-orange-500 text-white flex items-center justify-center text-2xl font-bold mb-4">
                  3
                </div>
                <h3 className="text-xl font-bold mb-2">Play and Connect</h3>
                <p className="text-gray-500">
                  Meet up, play basketball, and make new friends!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="download" className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                  Download JumpBall Today
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400">
                  Get ready to revolutionize your basketball experience.
                  Download the app now!
                </p>
              </div>
              <div className="space-x-4">
                <Button
                  className="inline-flex items-center"
                  onClick={() => document.location.replace(webUrl)}
                >
                  <Chrome className="mr-2 h-5 w-5" />
                  Try Online {">"}
                </Button>
                <Button
                  className="inline-flex items-center"
                  variant="outline"
                  disabled
                >
                  <Play className="mr-2 h-5 w-5" />
                  Google Play
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          © 2024 JumpBall. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <Link className="text-xs hover:underline underline-offset-4" to="#">
            Terms of Service
          </Link>
          <Link className="text-xs hover:underline underline-offset-4" to="#">
            Privacy
          </Link>
        </nav>
      </footer>
    </div>
  );
}
