import axios from "axios";
import SvgGoogleLogo from "../assets/google_logo";
import { BACKEND_URL } from "../config";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { Link, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  handleClickBack,
  logInWithAccessToken,
  passTokenToApp,
} from "@/auth_utils";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    password2: "",
    non_field_errors: "",
  });
  let navigate = useNavigate();

  const onSuccess = (
    tokenResponse: Omit<
      TokenResponse,
      "error" | "error_description" | "error_uri"
    >,
  ) => {
    setLoading(true);
    checkTokenInfo(tokenResponse);
  };

  const onError = (error: any) => {
    console.log("login failed:", error);
  };

  const login = useGoogleLogin({
    onSuccess,
    onError,
  });

  useEffect(() => {
    const jwt_token = localStorage.getItem("auth_token");
    if (jwt_token) {
      passTokenToApp(jwt_token);
    }
  }, []);

  const onFormSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    await axios
      .post(BACKEND_URL + "/api/auth/register/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast(
          "Your registration was processed correctly. You may now log in.",
          {
            type: "success",
            position: "bottom-right",
          },
        );
        navigate("/login/");
      })
      .catch((error) => {
        setErrors(error.response.data);
        console.log(error);
      });
  };

  const checkTokenInfo = async (tokenInfo: TokenResponse) => {
    if (tokenInfo) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenInfo["access_token"]}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenInfo["access_token"]}`,
            },
          },
        )
        .then(
          async (response) => {
            const newAuthToken = await logInWithAccessToken(
              tokenInfo["access_token"],
              response["data"],
            );
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-[100dvh] items-center justify-center bg-gray-100 px-4">
      <div className="mx-auto max-w-md w-full space-y-6">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight">
            Register your account
          </h1>
          <p className="mt-2 text-gray-500">
            Already have an account?{" "}
            <Link
              className="font-bold text-primary hover:underline"
              to="/login"
            >
              Log In
            </Link>
          </p>
        </div>
        <div className="space-y-4">
          <Button variant="outline" onClick={() => login()} className="w-full">
            <div style={{ display: "flex" }}>
              <div
                style={{ height: 18, marginRight: 8, minWidth: 18, width: 18 }}
              >
                <SvgGoogleLogo width={20} height={20} />
              </div>
              <div
                style={{
                  lineHeight: "18px",
                  fontFamily: '"Google Sans", arial, sans-serif',
                  fontWeight: 500,
                }}
              >
                Sign up with Google
              </div>
            </div>
          </Button>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-gray-100 px-2 text-gray-500">
                Or continue with
              </span>
            </div>
          </div>
          <form className="space-y-4" onSubmit={onFormSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  First Name
                </label>
                <Input id="first_name" name="first_name" placeholder="John" />
                {errors.first_name && (
                  <p className="text-red-500 ">{errors.first_name}</p>
                )}
              </div>
              <div>
                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  Last Name
                </label>
                <Input id="last_name" name="last_name" placeholder="Doe" />
                {errors.last_name && (
                  <p className="text-red-500 ">{errors.last_name}</p>
                )}
              </div>
            </div>
            <div>
              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                Email address
              </label>
              <Input
                id="email"
                name="username"
                placeholder="name@example.com"
                type="email"
              />
              {errors.username && (
                <p className="text-red-500 ">{errors.username}</p>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="flex items-center justify-between">
                  <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    Password
                  </label>
                </div>
                <Input
                  id="password"
                  placeholder="••••••••"
                  type="password"
                  name="password"
                />
                {errors.password && (
                  <p className="text-red-500 ">{errors.password}</p>
                )}
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    Repeat Password
                  </label>
                </div>
                <Input
                  id="password2"
                  placeholder="••••••••"
                  name="password2"
                  type="password"
                />
                {errors.password2 && (
                  <p className="text-red-500 ">{errors.password2}</p>
                )}
              </div>
            </div>
            <Button type="submit" disabled={loading} className="w-full">
              Register
            </Button>

            <Link to="/" onClick={handleClickBack}>
              <Button
                variant="outline"
                className="inline-flex w-full mt-2"
                type="submit"
                disabled={loading}
              >
                Back
              </Button>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
